/** @jsx jsx */
import { Fragment } from "react"
import { jsx, Styled } from "theme-ui"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout, { Container } from "components/layout"
import Apple from "components/svgs/apple"
import Hero from "components/hero"
import ImageRow from "components/image-row"
import ImageText from "components/image-text"
import List from "components/list"
import { TileInfo, TileLink, TileWrapper } from "components/tiles"
import Title from "components/title"

export const query = graphql`
  query FoodPageQuery {
    heroImage: file(relativePath: { eq: "hero/food.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    academicsImage: file(relativePath: { eq: "hero/academics.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    garden: file(relativePath: { eq: "food/garden.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    buckets: file(relativePath: { eq: "food/buckets.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    foodStand: file(relativePath: { eq: "food/food-stand.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 550) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    shirts: file(relativePath: { eq: "food/shirts.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const FoodPage = ({ data }) => (
  <Layout
    pageTitle="Food Systems"
    description="A sustainable food system strengthens local production and distribution infrastructures, and makes nutritious food accessible and affordable to all."
  >
    <Title icon={<Apple />}>Food Systems</Title>
    <Hero fluid={data.heroImage.childImageSharp.fluid} />

    <Container>
      <div sx={{ maxWidth: ["100%", "70%"] }}>
        <Styled.p>
          A sustainable food system strengthens local production and
          distribution infrastructures, and makes nutritious food accessible and
          affordable to all.
        </Styled.p>
        <Styled.p>
          Mohawk supports sustainable food systems by working to expand access
          to local food on campus, supporting pollinator populations, promoting
          the health and environmental impacts of everyday food choices, and
          developing knowledge through hands-on learning.
        </Styled.p>
      </div>
      <ImageRow>
        <Img fluid={data.garden.childImageSharp.fluid} />
        <Img fluid={data.buckets.childImageSharp.fluid} />
        <Img className="wide" fluid={data.foodStand.childImageSharp.fluid} />
      </ImageRow>
    </Container>

    <div sx={{ bg: "muted" }}>
      <Container>
        <Styled.h2>Key Initiatives</Styled.h2>
        <List icon={<Apple />}>
          <Fragment>
            Expansion of the Community Garden to forty-nine plots
          </Fragment>
          <Fragment>
            Local food Farm Stand and Bounty Bag programs established
          </Fragment>
          <Fragment>
            Thirty-six tree fruit orchard established in honour of Fennell
            Campus' agricultural heritage
          </Fragment>
          <Fragment>New six-hive honey bee apiary at Fennell Campus</Fragment>
          <Fragment>
            Annual Thanksgiving at the Farm Stand event to build awareness and
            celebrate local food
          </Fragment>
        </List>
      </Container>
    </div>

    <Container wide={true} spaced={false}>
      <TileWrapper>
        <TileInfo bg="darkPrimary" color="background">
          <Styled.p>
            <Styled.h2 as="span">300+</Styled.h2>
            <br />
            annual Community Garden participants learn to grow their own food
          </Styled.p>
        </TileInfo>
        <TileInfo bg="primary" color="background">
          <Styled.p>
            <Styled.h2 as="span">10,000 lbs</Styled.h2>
            <br />
            of local food purchased from the Farm Stand in 2019
          </Styled.p>
        </TileInfo>
        <TileInfo bg="accentTwo" color="dark">
          <Styled.p>
            <Styled.h2 as="span">354</Styled.h2>
            <br />
            Bounty Bags and Eat Better Bags purchased in 2019
          </Styled.p>
        </TileInfo>
        <TileInfo bg="secondary" color="dark">
          <Styled.p>
            <Styled.h2 as="span">300 lbs</Styled.h2>
            <br />
            of food donated to local food banks in 2019
          </Styled.p>
        </TileInfo>
      </TileWrapper>

      <ImageText image={data.shirts.childImageSharp.fluid}>
        <Styled.h2>Local Food Framework for Ontario Colleges</Styled.h2>
        <Styled.p>
          Increasing the amount of local food served on Ontario college campuses
          is an important opportunity for colleges to better serve their
          students and communities. "Increasing Local Food Procurement at
          Ontario's 24 Colleges" was a multi-stakeholder project led by Mohawk
          College in partnership with the Greenbelt Fund and the Government of
          Ontario.
        </Styled.p>
        <Styled.p>
          The resulting Framework is a step-by-step guide to increasing local
          food procurement at Ontario's 24 colleges, providing templates, tools
          and resources to help college and foodservice staff procure, prepare
          and promote more local food on campus.
        </Styled.p>
      </ImageText>
    </Container>

    <Container>
      <Styled.h2>Continue Reading</Styled.h2>
      <TileLink
        to="/academics"
        image={data.academicsImage.childImageSharp.fluid}
      >
        Academics &amp; Applied Research
      </TileLink>
    </Container>
  </Layout>
)

export default FoodPage
